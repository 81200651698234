/*global MediumEditor */
'use strict';
angular.module('angular-medium-editor', []).directive('mediumEditor', function () {
  function toInnerText(value) {
    var tempEl = document.createElement('div'), text;
    tempEl.innerHTML = value;
    text = tempEl.textContent || '';
    return text.trim();
  }
  return {
    require: 'ngModel',
    restrict: 'AE',
    scope: { bindOptions: '=' },
    link: function (scope, iElement, iAttrs, ngModel) {
      var maxLength = scope.bindOptions.maxlength;
      angular.element(iElement).addClass('angular-medium-editor');
      // Global MediumEditor
      ngModel.editor = new MediumEditor(iElement, scope.bindOptions);
      ngModel.$render = function () {
        ngModel.editor.setContent(ngModel.$viewValue || '');
        var placeholder = ngModel.editor.getExtensionByName('placeholder');
        if (placeholder) {
          placeholder.updatePlaceholder(iElement[0]);
        }
      };
      ngModel.$isEmpty = function (value) {
        if (/[<>]/.test(value)) {
          return toInnerText(value).length === 0;
        } else if (value) {
          return value.length === 0;
        } else {
          return true;
        }
      };
      ngModel.editor.subscribe('editableInput', function (event, editable) {
        var text = editable.innerHTML.trim(), oldVal = ngModel.$viewValue;
        if (!_.isUndefined(maxLength) && _getMaxLengthCounter(text) < 0) {
          if (_stripTags(oldVal).length === maxLength) {
            editable.innerHTML = oldVal;
            return ngModel.$setViewValue(oldVal);
          }
          var position = _findFirstDiffPos(text, oldVal), oldTextWithoutTags = _stripTags(oldVal), textWithoutTags = _stripTags(text), diffText = _getPastText(oldTextWithoutTags, textWithoutTags), newText = (oldTextWithoutTags + diffText).substring(0, parseInt(maxLength)), cutTextPosition = _findFirstDiffPos(oldTextWithoutTags, newText), cutText = newText.substring(cutTextPosition);
          text = _insert(oldVal, cutText, position);
          editable.innerHTML = text;
        }
        scope.bindOptions.countCharacters = _countCharacters(text);
        ngModel.$setViewValue(text);
      });
      scope.$watch('bindOptions', function (bindOptions) {
        ngModel.editor.init(iElement, bindOptions);
      });
      scope.$on('$destroy', function () {
        ngModel.editor.destroy();
      });
      /**
				 *
				 * @param text
				 * @returns {*}
				 * @private
				 */
      function _getMaxLengthCounter(text) {
        if (_.isUndefined(maxLength)) {
          return false;
        }
        if (_.isNull(text) || _.isUndefined(text)) {
          return parseInt(maxLength);
        }
        return parseInt(maxLength) - parseInt(_countCharacters(text));
      }
      /**
				 *
				 * @param text
				 * @returns {*}
				 * @private
				 */
      function _countCharacters(text) {
        if (!text) {
          return 0;
        }
        return _stripTags(text).replace(/\s+/g, ' ').length;
      }
      /**
				 *
				 * @param text
				 * @returns {*}
				 * @private
				 */
      function _stripTags(text) {
        //ignore line breaks
        text = text.replace(/(\r\n|\n|\r)/gm, '').replace(/&nbsp;/gi, ' ');
        //ignore all tags
        return _strip(text).replace(/^([\t\r\n]*)$/, '');
      }
      /**
				 *
				 * @param html
				 * @returns {*}
				 * @private
				 */
      function _strip(html) {
        var tmp = document.createElement('div');
        tmp.innerHTML = html;
        if (tmp.textContent === '' && _.isUndefined(tmp.innerText)) {
          return '';
        }
        return tmp.textContent || tmp.innerText;
      }
      /**
				 *
				 * @param str1
				 * @param str2
				 * @returns {number}
				 * @private
				 */
      function _findFirstDiffPos(str1, str2) {
        var shorterLength = Math.min(str1.length, str2.length);
        for (var i = 0; i < shorterLength; i++) {
          if (str1[i] !== str2[i]) {
            return str1[i - 1] === '<' ? i - 1 : i;
          }
        }
        if (str1.length !== str2.length) {
          return shorterLength;
        }
        return -1;
      }
      /**
				 *
				 * @param str1
				 * @param str2
				 * @returns {*|string}
				 * @private
				 */
      function _getPastText(str1, str2) {
        var firstPosition = _findFirstDiffPos(str1, str2), lastTextPart = str1.substring(firstPosition + 1);
        if (str2.length === str2.lastIndexOf(lastTextPart)) {
          return str2.substring(firstPosition);
        }
        return str2.substring(firstPosition, str2.lastIndexOf(lastTextPart) - 1);
      }
      /**
				 *
				 * @param str
				 * @param value
				 * @param position
				 * @returns {*}
				 * @private
				 */
      function _insert(str, value, position) {
        return str.slice(0, position) + value + str.slice(position);
      }
    }
  };
});